import React, { useContext, useEffect, useState } from 'react';
import styled                                     from '@emotion/styled';

import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Typography                    from '@interness/web-core/src/components/text/Typography/Typography';

import { StoreContext } from '../../context/Store';

const LegalPage = ({ type, children }) => {
  const { settings } = useContext(StoreContext);
  const [hasCustom, setHasCustom] = useState(false);
  const [content, setContent] = useState(null);
  const { error, loading } = settings;

  const headings = {
    agb: 'Allgemeine Geschäftsbedingungen',
    widerruf: 'Widerrufsbelehrung und Widerrufsformular',
    'versand-und-zahlung': 'Versand- und Zahlungsbedingungen'
  }

  useEffect(() => {
    if (settings.data) {
      const page = settings.data.legal_pages.filter(page => page.type === type);
      if (page.length !== 0) {
        setHasCustom(true);
        setContent(page[0].content);
      }
    }
    //eslint-disable-next-line
  }, [settings])

  return (
    <Wrapper>
      <Spacer/>
      <Heading>{headings[type]}</Heading>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      <Typography>
        {(!loading && !hasCustom && !content) || error
          ? children
          : <div dangerouslySetInnerHTML={{ __html: content }}/>}
      </Typography>
      <Spacer/>
    </Wrapper>
  )
};

export default LegalPage;